import React, { Component } from 'react';
import Radium from 'radium';

class NominationReason extends Component {
    state = {
        valid: null
    }

    validateField = () => {
        const { data, value } = this.props;
        if (data[value] === "" || !data[value]) {
            this.setState({ valid: false })
        } else {
            this.setState({ valid: true })
        }
    }

    render() {
        const { valid } = this.state;
        const { title, value, data, id, handleChange, charsRemaining = "480", colour } = this.props;
        const style = {
            color: "#616266",
            ':focus': {
                outline: "none",
                border: `1px solid ${colour}`,
                borderRadius: "5px",
                boxShadow: `0 0 10px ${colour}`
            }
        };
        return (
            <div className="reason" id={id}>
                <h6 className="h6-question question-remaining">{title} <span>({charsRemaining} characters remaining)</span></h6>
                <textarea
                    name={value}
                    value={data[value]}
                    label={title}
                    id={`answer_${id}`}
                    maxLength="480"
                    rows="6"
                    onChange={handleChange}
                    onBlur={this.validateField}
                    className="reason-textarea"
                    style={style}
                />
                {valid === false && <div className="reason-alert alert alert-danger">This field cannot be empty</div>}
            </div>
        );
    }
}

export default Radium(NominationReason);