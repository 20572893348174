import React, {Component} from 'react';
import _ from "lodash";
import '../../styles/ratingBar.css';


class RatingBar extends Component {
    state = {
        rating: [
            {selected: false, value: 1},
            {selected: false, value: 2},
            {selected: false, value: 3},
            {selected: false, value: 4},
            {selected: false, value: 5}         
        ]
    }

    handleSelected = (selected, colour) => {
        const selectedStyle = {
            'backgroundColor':'white'
        };
    
        const selectedStyle2 = {
            'border': '2px solid black',
            'backgroundColor': colour,
            color: "white"
        };

        const spanStyle = selected ? selectedStyle2 : selectedStyle;
        return spanStyle
    }

    handleClick = (value) => {
        const selectedIndex = _.findIndex(this.state.rating, rate => { return rate.selected === true});
        if(selectedIndex === -1 ) {
            const index = _.findIndex(this.state.rating, (rate) => { return rate.value === value });
            const rating = [...this.state.rating];
            rating[index].selected = !rating[index].selected
            this.setState({rating});
        } else {
            const index = _.findIndex(this.state.rating, (rate) => { return rate.value === value });
            const rating = [...this.state.rating];
            rating[selectedIndex].selected = !rating[selectedIndex].selected;
            rating[index].selected = !rating[index].selected
            this.setState({rating});
        }
        this.props.handleRating(this.props.id, value);
    }

    render() {
        const name = this.props.name;
        return (
            <div className="rating-bar" name={name}>
                {this.state.rating.map(rate => (
                    <span 
                        className="dot" 
                        key={rate.value} 
                        style={this.handleSelected(rate.selected, this.props.colour)}
                        onClick={() => this.handleClick(rate.value)}
                    >
                        {rate.value}
                    </span>                 
                ))}
            </div>
                
        );
    }
}

export default RatingBar;