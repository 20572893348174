export const awardStyle = colour => {
    const styles = {
        base: {
            color: 'white',
            backgroundColor: colour,
            boxShadow: "1px 2px 10px 0px #888888",
            textDecoration: "none",
            ':hover': {
                color: colour,
                backgroundColor: "white"
            },
            ':focus': {
                boxShadow: "inset 0px 0px 10px #888888"
            }
        },
        ribbon: {
            background: "#f0f0f0",
            color: colour,
            fontWeight: "bold"
        }
    }
    return styles;
}

export const awardStyleVoted = colour => {
    const styles = {
        base: {
            color: 'white',
            backgroundColor: colour,
            boxShadow: "1px 2px 10px 0px #888888",
            textDecoration: "none",
            cursor: "default"
        },
        ribbon: {
            background: "#f0f0f0",
            color: colour,
            fontWeight: "bold"
        }
    };

    return styles
}