import React from 'react';
import {NavLink} from 'react-router-dom';
import logo from '../assets/logo_64px.png'
import '../styles/navbar.css';

const NavBar = props => {
    return (  
        <nav className="navbar navbar-expand-lg navbar-dark">
                <NavLink className="nav-brand" to="/">
                    <img 
                        src={logo} 
                        alt="CEAs Logo" 
                        className="rounded-circle logo"
                        style={{padding: "5px", border: "2px solid white"}}
                    />    
                </NavLink>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                { props.loggedIn &&
                    <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link nav-nom" to="/nominate">Nominate</NavLink>     
                            </li>
                            {/* <li className="nav-item">
                                <NavLink className="nav-link" to="/awards">Awards</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/shortlist">Shortlist</NavLink>
                            </li> */}
                        </ul>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/login" onClick={props.logout}>Logout</NavLink>     
                            </li>  
                        </ul>  
                    </div>
                }
                  
        </nav>
    );
}
 
export default NavBar;