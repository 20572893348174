import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { toast } from 'react-toastify';
import loadGif from '../assets/load_small.gif';

class PasswordForm extends Form {
    state = {
        data: { password: "" },
        loading: false,
        errors: {}
    };

    schema = {
        password: Joi.string()
            .required()
            .label("Password")
    }

    doSubmit() {
        this.setState({ loading: true });
        const email = this.props.email;
        try {
            this.props.webAuth.passwordlessLogin({
                connection: 'email',
                email,
                verificationCode: this.state.data.password
              }, (err, res) => {
                if(err) {
                    toast.error("Wrong verification code. Please try again")
                    console.log("Something went wrong", err);
                } else {
                    // console.log("YEY2", res); 
                }
              });
            this.setState({ loading: false })
        } catch (err) {
            this.setState({ loading: false })
            console.log(err)
        }
    }
    
    determineGrade = () => {
       if(this.props.role === "admin") return "Password";
       if(this.props.role === "manager") return "Password";
       
       return "Code";
    }

    render() {
        return ( 
            <React.Fragment>
                <div className="card bg-light mb-3" style={{ minWidth: '500px' }}>
                    <div className="card-header">
                        <h5>Login</h5>
                    </div>
                    <form className="px-4 py-3" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            {this.renderInput("password", this.determineGrade(), "password")}
                            { this.state.loading ? 
                                this.renderButton(<img src={loadGif} height="20" alt="Submitting.." />)
                                : this.renderButton("Submit")
                            }
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }
}

export default PasswordForm;