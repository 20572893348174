import Joi from 'joi-browser';

export const nominationFormSchema = {
    nominatorEmail: Joi.string()
        .required()
        .email()
        .label("Your Email"),
    nomineeName: Joi.string().min(1)
        .required()
        .label("Nominee Name"),
    nomineeEmail: Joi.string().min(1)
        .required()
        .email()
        .label("Nominee Email")
        .email({ minDomainAtoms: 2 })
        .regex(/^[A-Za-z0-9._%+-]+@coveainsurance.co.uk$/)
        .options({
            language: {
                string: {
                    regex: {
                        base: "must be a valid Covea Insurance Email."
                    }
                }
            }
        }
    ),
    department: Joi.string()
        .required()
        .label("Department"),
    nomineeLocation: Joi.string()
        .required()
        .label("Location"),
    award: Joi.string()
        .required(),
    nominationDetails: Joi.array().items(
        Joi.object({
            question: Joi.string(),
            answer: Joi.string().label("Question").required().min(1),
            score: Joi.number().greater(0).required(),
            id: Joi.number(),
            charsRemaining: Joi.number()
        })
    ),
    reason: Joi.string().min(1)
        .required()
        .label("Reason for Nomination")
}