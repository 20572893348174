import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Radium from 'radium';
import { awardStyle, awardStyleVoted } from '../../config/award.config';
import '../../styles/award.css'

class Award extends Component {
    render() {
        const RadiatingLink = Radium(Link);
        const { awardName, colour, operation, link, voted, newAward } = this.props;
        const votedLink = voted ? "nominate" : link;
        const styles = voted ? awardStyleVoted(colour) : awardStyle(colour);
        return (
            <div className="award-outer col-md-4">
                <div className="award">
                    {voted ? <div className="corner-ribbon top-left" style={styles.ribbon}> Voted! </div> : null}
                    <RadiatingLink to={`/${votedLink}`} style={styles.base} colour={colour}>
                        <div>{operation} for</div>
                        <h1>{awardName}</h1>
                        {newAward ? <div className="pulse" style={{marginTop: "-28px", position: "relative", top: "30px"}}>New!</div> : null}
                    </RadiatingLink>
                </div>
            </div>
        );
    }
}

export default Radium(Award);