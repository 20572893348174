import axios from "axios";
import {awardConfig} from "../config/awardService.config";

export const getUser = async email => {

  const url = awardConfig.getEmployeesUrl + email

  const headers = {
      headers: {
          "Content-Type": "application/json",
          "x-api-key": "RUrqvLQlTScJvAGOvWfRa0rIPlKCEmU1p1C7SY3b"
      }
    };

  return await axios.get(url, headers).then(response => {
    
      if(response.status !== 200) {
        console.log(response);
        throw new Error("getUser error response status - " + response.status + ", reason - " + response);
      }

      const result = response.data;
      // console.log("Retrieving awards", result);

      return result;
  }).catch(error => {
      console.log("getUser request exception", error)
  })

}
