import axios from "axios";
import {awardConfig} from "../config/awardService.config";

export const getAwards = async email => {
    const url = `${awardConfig.getAwardsUrl}?nominator=${email}`;
    const headers = { "x-api-key": awardConfig.apiKey };
    const response = await axios.get(url, { headers: headers });

    return (response.status >= 200 && response.status < 299) ?
        response.data
        : new Error("Lambda returned status " + response.status + ", reason: " + response);
}

export const getQuestionsForNomination = async (award) => {
    const url = `${awardConfig.getQuestionsUrl}/${award}/questions`;
    const headers = { "x-api-key": awardConfig.apiKey };
    const response = await axios.get(url, { headers: headers });

    return (response.status >= 200 && response.status < 299) ?
        response.data
        : new Error("Lambda returned status " + response.status + ", reason: " + response);
}