import axios from "axios";
import { awardConfig } from "../config/awardService.config";

export const getEmployee = async (email) => {
  const url = `${awardConfig.getEmployeesUrl}${email}`;
  const headers = { "x-api-key": awardConfig.apiKey };
  const response = await axios.get(url, { headers: headers });

  return (response.status >= 200 && response.status < 299) ?
    response.data
    : new Error("Lambda returned status " + response.status + ", reason: " + response);
}

export const getDepartments = () => {
  return ["Actuarial & BI", "Affinity Partners", "Agency", "Claims - High & Mid Net Worth", "Claims Motor Engineers", "Claims Motor Operations",
    "Claims Operations - Non Motor", "Commercial & Property Claims", "Commercial Lines Distribution", "Commercial Lines Underwriting and Ops",
    "Credit Control", "Facilities", "Finance", "Financial Crime", "Hebble Law", "High & Mid Net Worth", "Home Claims", "HR and L&D", "Internal Audit",
    "IT", "Liability Claims", "Marketing & Communications", "Motor Trade", "Other", "Personal Lines Pricing", "Personal Lines Product Development & Distribution",
    "Personal Lines Underwriting", "PPI Operations", "Protection", "Provident / Direct", "Risk & Compliance", "Schemes", "Technical Motor Claims"]
}

export const getLocations = () => {
    return [ "Halifax", "Reading", "West Malling", "Manchester", "Bristol", "Birmingham", "Chelmsford", "London" ];
}