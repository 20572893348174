import React, { Component } from 'react';
import Award from './common/award';
import auth0 from 'auth0-js';
import { webAuthConfig } from '../config/webAuth.config';
import { getAwards } from '../services/award.service';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

import '../styles/awardsPage.css';

class AwardsPage extends Component {

    state = {
        operation: "Nominate",
        awards: null,
        email: sessionStorage.getItem("email"),
        loading: false
    }

    componentDidMount = async () => {
        try {
            this.authoriseUserAndSetLocalStorage(this.state.email);
            let awards = await getAwards(this.state.email);
            awards = awards.sort((a, b) => a.order - b.order);
            this.props.handleAwards(awards);
            this.setState({ awards })
        } catch (err) {
            this.props.history.push("/login")
            console.log(err)
            toast.error("An error occurred during the login process. Please try again.");
        }
    }

    authoriseUserAndSetLocalStorage = (email) => {
        const webAuth = new auth0.WebAuth(webAuthConfig(this.state.email));
        if(sessionStorage.getItem("accessToken") === null || sessionStorage.getItem("idToken") === null) {
            webAuth.parseHash({ nonce: email }, (err, authResult) => {
                if (err) { 
                    console.log(err);
                    this.props.history.push("/login");
                }
                if (!authResult) {
                    // console.log("No Auth Result was produced. Cannot save to Session Storage.");
                    this.props.history.push("/login");
                } else {
                    sessionStorage.setItem("accessToken", authResult.accessToken);
                    sessionStorage.setItem("idToken", authResult.idToken);
                    this.props.loginState();
                    this.props.history.push("/nominate");
                }
            });
        }
    }

    render() { 
        const { operation, awards } = this.state;
        return awards ? 
            <React.Fragment>
                {
                    awards.map(award => (
                        <Award 
                            key={award.name}
                            awardName={award.name}
                            link={`nominate/${award.name}`}
                            operation={operation}
                            colour={award.colour}
                            voted={award.votedFor}
                            newAward={award.new}
                        />
                    ))
                }
            </React.Fragment>
        :  
            <Loader 
                type="Puff"
                color="#dc3c3f"
                height="100"	
                width="100"
            />
    }
}

export default AwardsPage;