import React from 'react';

const Name = ({ title, handleChange, errors, data, value, disabled, email, eligible }) => {
    return (  
        <div className="wrapper">
            <div className="first">
                <h5>{title}</h5>
                {
                    eligible ? 
                    <p style={{marginBottom: "5px", fontSize:"10px"}}>({eligible})</p>
                    : null
                }
            </div>
            <div className="second">
                <input 
                    type="text"
                    name={value}
                    value={data[value] || email || ""}
                    label={title}
                    onChange={handleChange}
                    className="form-control"      
                    disabled={disabled}
                />
                {errors[value] && <div className="alert alert-danger">{errors[value]}</div>}
            </div>                  
        </div>
    );
}

 
export default Name;