import React from 'react';

import RatingBar from './ratingBar';
import NominationReason from './nominationReason';

const Question = ({ questions, onSelect, errors, value, data, handleChange, colour }) => {
    return (  
        <React.Fragment>
            {questions.questions.map(question => (
                <div className="question" key={question.id}>
                    <h6 className="h6-question" style={{color: "#616266"}}>{question.question}</h6>
                    <RatingBar 
                        handleRating={onSelect}
                        id={question.id}
                        name={`score_${question.id}`}
                        colour={colour}
                    />
                    {errors[value] && <div className="alert alert-danger">{errors[value]}</div>}
                    <NominationReason
                        title="Please give your evidence here"
                        value="answer"
                        id={question.id}
                        handleChange={handleChange}
                        errors={errors}
                        data={question}
                        charsRemaining={question.charsRemaining}
                        colour={colour}
                    />
                </div>
            ))}
        </React.Fragment>
    );
}

 
export default Question;