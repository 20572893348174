import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Question from './common/question';
import Joi from "joi-browser";
import { nominationFormSchema } from '../config/schema.config';
import Name from './common/name';
import Dropdown from '../components/common/department';
import NominationReason from './common/nominationReason';
import { postNomination } from '../services/nominee.service';
import { getDepartments, getLocations } from "../services/employee.service";
import { getQuestionsForNomination, getAwards } from '../services/award.service'
import loadGif from '../assets/load_med.gif';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import { modalStyle } from '../config/modal.config';
import { hexToRgb } from '../utils/conversions.utils';
import '../styles/nominationForm.css'


class NominationForm extends Component {
    state = {
        departments: getDepartments(),
        email: sessionStorage.getItem("email"),
        questions: null,
        award: null,
        data: {
            nominationDetails: null
        },
        locations: getLocations(),
        reasonCharsRemaining: 480,
        submitted: false,
        loading: false,
        errors: {}
    };

    schema = nominationFormSchema;

    componentDidMount = async () => {
        try {
            const awards = await getAwards(this.state.email);
            const award = await awards.find(award => award.name === this.props.match.params.award);
            if (award.votedFor) {
                this.props.history.push("/nominate");
            }
            const questionList = await getQuestionsForNomination(award.name);
            const questions = questionList.Items[0];
            const data = {
                nominatorEmail: this.state.email,
                award: award.name,
                nominationDetails: questions.questions
            };

            this.setState({ award, questions, data });
        } catch (err) {
            console.log(err);
            this.props.history.push("/error");
        }
    }

    handleRating = (id, value) => {
        const { nominationDetails } = { ...this.state.data };
        const errors = { ...this.state.errors };
        nominationDetails[id].score = value
        const data = { ...this.state.data };
        data.nominationDetails = nominationDetails;
        const property = {
            name: "nominationDetails",
            value: nominationDetails
        };
        const errorMessage = this.validateProperty(property);
        if (errorMessage) errors[property.name] = errorMessage;
        else delete errors[property.name];

        this.setState({ data })
    }

    validate = () => {
        const options = { abortEarly: false };
        const { error } = Joi.validate(this.state.data, this.schema, options);
        if (!error) return null;

        const errors = {};
        for (let item of error.details) {
            errors[item.path[0]] = item.message;
        }
        return errors;
    }

    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { error } = Joi.validate(obj, schema);

        return error ? error.details[0].message : null;
    }

    handleSubmit = async (e) => {
        this.setState({ loading: true });
        try {
            const nomination = this.state.data;
            nomination.nomineeEmail = nomination.nomineeEmail.toLowerCase();
            if(nomination.nomineeEmail === nomination.nominatorEmail) {
                throw new Error("You cannot vote for yourself!");
            }
            await postNomination(nomination);
            this.setState({ loading: false, submitted: true })
        }
        catch (err) {
            console.log(err);
            if (err.response && err.response.status === 409) {
                toast.error("You have already nominated this person for this award!");
            } else {
                toast.error(err.message)
            }
            this.setState({ loading: false })
        }
    }

    handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };

        if (input.name === "reason") {
            const remaining = this.calculateRemainingLength(input);
            this.setState({ reasonCharsRemaining: remaining });
        }

        if (input.name === "answer") {
            //get id of textarea
            const id = input.id.split("_")[1];
            const { nominationDetails } = { ...this.state.data };

            //calculate remaining length
            const remaining = this.calculateRemainingLength(input);

            //save the values
            nominationDetails[id].answer = input.value
            nominationDetails[id].charsRemaining = remaining;

            //get current data state and replace nomination details
            const data = { ...this.state.data };
            data.nominationDetails = nominationDetails;

            //validate field against schema
            this.handlePropertyAndValidate("nominationDetails", nominationDetails, errors, input);
            this.setState({ data, errors })
        } else {

            const errorMessage = this.validateProperty(input);
            if (errorMessage) errors[input.name] = errorMessage;
            else delete errors[input.name];

            const data = { ...this.state.data };
            data[input.name] = input.value;

            this.setState({ data, errors });
        }
    }

    calculateRemainingLength(input) {
        const length = input.value.length;
        const maxLength = input.maxLength;
        const remaining = maxLength - length;
        return remaining;
    }

    handlePropertyAndValidate(propertyName, data, errors, input) {
        const property = {
            name: propertyName,
            value: data
        };
        const errorMessage = this.validateProperty(property);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
    }

    render() {
        const { data, errors, departments, questions, award, locations } = this.state;
        return award ? (
            <React.Fragment>
                <ToastContainer />
                <ReactModal
                    isOpen={this.state.submitted}
                    ariaHideApp={false}
                    style={modalStyle(award.colour)}
                >
                    <h2 style={{ marginBottom: "20px" }}>Success!</h2>
                    <p>Your nomination has successfully been submitted.</p>
                    <Link
                        to="/nominate"
                        className="btn btn-success"
                        style={{ backgroundColor: "#78A02E" }}
                    >
                        Continue
                    </Link>
                </ReactModal>
                <div className="nomination-form">
                    <div className="nomination-header" style={{ backgroundColor: award.colour, color: "white" }}>
                        <h3><span>{`${award.name} Nomination`}</span></h3>
                    </div>
                    <div className="nomination-body" style={{ backgroundColor: "white", color: award.colour }}>
                        <div style={{ margin: 0 }} className="alert alert-warning dismissible"><strong>Please note</strong>: if you leave this page before submitting, your nomination will be lost.</div>
                        <Name
                            title="Nominee Name:"
                            value="nomineeName"
                            handleChange={this.handleChange}
                            data={data}
                            errors={errors}
                            eligible={award.eligible}
                            colour={award.colour}
                        />
                        <Name
                            title="Nominee Email:"
                            value="nomineeEmail"
                            handleChange={this.handleChange}
                            data={data}
                            errors={errors}
                            colour={award.colour}
                        />
                        <Dropdown
                            data={departments}
                            handleChange={this.handleChange}
                            title="Department"
                            name="department"
                            styleClass="department"
                            colour={award.colour}
                        />
                        <Dropdown
                            data={locations}
                            handleChange={this.handleChange}
                            title="Location"
                            name="nomineeLocation"
                            styleClass="department"
                            colour={award.colour}
                        />
                        <div style={{marginTop: "20px"}} className="award-description">
                            <p style={{backgroundColor: `rgba(${hexToRgb(award.colour).r},${hexToRgb(award.colour).g},${hexToRgb(award.colour).b}, 0.2)`, borderRadius: "5px"}}>{award.description}</p>
                            <p style={{backgroundColor: `rgba(${hexToRgb(award.colour).r},${hexToRgb(award.colour).g},${hexToRgb(award.colour).b}, 0.2)`, borderRadius: "5px"}}>Scoring levels: 1 - Never, 2 - Some of the time, 3 - A lot of the time, 4 - The vast majority of the time, 5 - Always</p>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <strong>Based on a scale of 1-5, to what degree does the person fulfil these statements? Please give examples to evidence your scores.</strong>
                        </div>
                        <div className="nomination-questions" >
                            <Question
                                onSelect={this.handleRating}
                                questions={questions}
                                value="question"
                                errors={errors}
                                data={data.nominationDetails}
                                handleChange={this.handleChange}
                                colour={award.colour}
                            />
                            <h5>Summary Question</h5>
                            <NominationReason
                                title="What's the overarching reason you believe this person is worthy of winning this award?"
                                value="reason"
                                handleChange={this.handleChange}
                                errors={errors}
                                data={data}
                                charsRemaining={this.state.reasonCharsRemaining}
                                id="reason"
                                colour={award.colour}
                            />
                        </div>
                        <div style={{ marginTop: "20px", marginBottom: "0" }} className="alert alert-danger"><strong>Please note</strong>: once your submission has been made, you will no longer be able to access or edit it.</div>
                    </div>
                    <div className="nomination-submit">
                        <button
                            disabled={this.validate()}
                            className="btn"
                            onClick={this.handleSubmit}
                        >
                            {this.state.loading ?
                                <img src={loadGif} height="40" alt="Submitting.." />
                                : "Submit"
                            }
                        </button>
                    </div>
                </div>

            </React.Fragment>
        ) : <Loader
                type="Puff"
                color="#dc3c3f"
                height="100"
                width="100"
            />
    }
}

export default NominationForm;