import React from 'react';
import '../../styles/department.css'

const Dropdown = ({ data, handleChange, title, name }) => {
    return (
        <div className="wrapper">
            <div className="first">
                <h5>{title}:</h5>
            </div>
            <div className="second">
                <form>
                    <select required
                        className={`form-control`}
                        name={name}
                        defaultValue="default"
                        onChange={handleChange}
                        style={{ textAlign: "center", textAlignLast: "center" }}
                    >
                        <option disabled value="default">--- Select {title} ---</option>
                        {data.map(item => (
                            <option
                                key={item}
                                value={item}
                            >
                                {item}
                            </option>
                        ))}
                    </select>
                </form>
            </div>
        </div>

    );
}

export default Dropdown;