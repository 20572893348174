import axios from "axios";
import {awardConfig} from "../config/awardService.config";

export const getNominationReasons = async email => {
  const url = awardConfig.getReasonsUrl;
  const headers = { "x-api-key": awardConfig.apiKey };
  const response = await axios.get(url, { headers: headers });

  return (response.status >= 200 && response.status < 299) ? 
    response.data 
    : new Error("Lambda returned status " + response.status + ", reason: " + response);
};


export const getNominees = async (award) => {
  const url = `${awardConfig.getNomineesUrl}/${award}/nominees`;
  const headers = { "x-api-key": awardConfig.apiKey };
  const response = await axios.get(url, { headers: headers });

  return (response.status >= 200 && response.status < 299) ? 
    response.data 
    : new Error("Lambda returned status " + response.status + ", reason: " + response);
};

export const postNomination = async (nomination) => {
    const url = `${awardConfig.postNominationUrl}`;
    const headers = { "x-api-key": awardConfig.apiKey };
    const response = await axios.post(url, nomination, {headers: headers});

    return (response.status >= 200 && response.status < 299) ? 
        response.data 
        : new Error("Lambda returned status " + response.status + ", reason: " + response);
};
