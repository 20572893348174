import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import PasswordForm from './passwordForm'
import { getEmployee } from '../services/employee.service';
import { ToastContainer, toast } from 'react-toastify';
import loadGif from '../assets/load_small.gif';
import auth0 from 'auth0-js';
import { webAuthConfig } from '../config/webAuth.config';
import 'react-toastify/dist/ReactToastify.min.css';

class LoginForm extends Form {
    state = {
      role: "user",
      submited: false,
      data: { username: "" },
      webAuth: null,
      loading: false,
      errors: {}
    };

    schema = {
      username: Joi.string()
        .required()
        .label("Username")
        .email({minDomainAtoms: 2})
        .regex(/^[A-Za-z0-9._%+-]+@coveainsurance.co.uk$/)
        .options({ 
            language: {
                string: {
                    regex: {
                        base: "must be a valid Covea Insurance Email."
                    }
                }
            }}
        )
    };    

    componentDidMount() {
        // console.log("Release version");
        if(sessionStorage.getItem("accessToken") !== null || sessionStorage.getItem("idToken") !== null) {
            this.props.history.push("/nominate");
        }
    }

  doSubmit = async () => {
        this.setState({ loading: true});
        const email = this.state.data.username.toLowerCase();
        try {
            //get employee
            await getEmployee(email);
            this.props.handleEmail(email);
            const config = webAuthConfig(email)            
            const webAuth = new auth0.WebAuth(config);
            this.props.handleWebAuth(webAuth);
            // console.log(this.props.webAuth)
            this.props.webAuth.passwordlessStart({
                connection: 'email',
                email,
                send: "code"
            }, function (err, res) {
                if(err) {
                    toast.error(err)
                    console.log("Something went wrong", err);
            }
                // console.log("YEY", res);     
            });
            toast.success("Please check your inbox for the 6-digit code")
            this.setState({submited: true, loading: false});
        }
        catch (err) {
            if (err.response && err.response.status === 404){
                console.log(err.response)
                toast.error("Employee is not Grade 13 or above");
            } else {
                toast.error(err.message);
            }
            this.setState({ loading: false });
        }
  }

    render() {
        return (
        !this.state.submited ?
            <React.Fragment>
                <ToastContainer />
                <div className="card bg-light mb-3" style={{width: "500px"}}>
                <div className="card-header">
                    <h5>Login</h5>
                </div>
                <form className="px-4 py-3" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                    {this.renderInput("username", "Email")}
                    {
                       this.state.loading ? 
                        this.renderButton(<img src={loadGif} height="20" alt="Submitting.." />)
                        : this.renderButton("Submit")
                    }
                    </div>
                </form>
                </div>
            </React.Fragment>
            :
            <React.Fragment>
                <ToastContainer 
                    autoClose={10000}
                />
                <PasswordForm 
                    webAuth={this.props.webAuth}
                    role={this.state.role}
                    email={this.state.data.username}/>
            </React.Fragment>
        );
    }
}

export default LoginForm;
