import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import './App.css';
import NavBar from './components/navbar'
import LoginForm from './components/loginForm';
import NominationForm from './components/nominationForm';
import AwardsPage from './components/awardsPage';
// import PanelPage from './components/panelPage';
// import AwardNominees from './components/awardNominees';
// import ShortlistPage from './components/shotlistPage';
// import ShortlistNomineesPage from './components/shortlistNomineesPage';
// import NomineeInfo from './components/awardNomineeInfo';
import ErrorPage from './components/errorPage';
import NominationSuccessPage from './components/nominationSuccessPage';
import { getUser } from './services/authService';

class App extends Component {
    state = {
      loggedIn: false,
      awards: null,
      webAuth: null,
      email: null
    }

  componentDidMount = async () => {
    if (sessionStorage.getItem("accessToken") === null || sessionStorage.getItem("idToken") === null) {
        this.setState({ loggedIn: false });
    } else {
        this.setState({ loggedIn: true });
    }
  }

  getUser = async (email) => {
    const user = await getUser(email);
    return user;
  } 

  setWebAuth = (webAuth) => {
    this.setState({ webAuth })
  };

  setEmail = (email) => {
    this.setState({email})
    sessionStorage.setItem("email", email)
  }

  setLoginState = () => {
    this.setState({ loggedIn: true });
  }

  logoutState = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("email");

    this.setState({ loggedIn: false });
  }

  setAwards = (awards) => {
    this.setState({ awards });
  }

  render() {
    return (
      <div className="App">
        <NavBar loggedIn={this.state.loggedIn} logout={this.logoutState} />
        <div className="container">
          <Switch>
            <Route path="/login" render={props => 
                <LoginForm 
                    handleWebAuth={this.setWebAuth}
                    webAuth={this.state.webAuth}
                    handleEmail={this.setEmail}
                    {...props} 
                />} 
            />
            <Route path="/nominate/:award/success" component={NominationSuccessPage} />
            <Route path="/nominate/:award" render={props =>
                <NominationForm
                    awards={this.state.awards}
                    {...props}
                />} 
            />
            <Route path="/nominate" render={props => 
                <AwardsPage 
                    webAuth={this.state.webAuth}
                    loginState={this.setLoginState}
                    handleAwards={this.setAwards}
                    awards={this.state.awards}
                    {...props} 
                />} 
            />
            {/* <Route path="/nominate" render={props => <AwardsPage webAuth={this.webAuth} loginState={this.setLoginState} getUser={this.getUser} {...props} />} /> */}
            {/* <Route path="/awards/:award/nominees/:id" component={NomineeInfo} />
            <Route path="/awards/:award/nominees" component={AwardNominees} />
            <Route path="/awards" render={props => <PanelPage awards={this.state.awards} {...props} />} />
            <Route path="/shortlist/:award/nominees/:id" component={ShortlistNomineesPage} />
            <Route path="/shortlist/:award/nominees" component={ShortlistNomineesPage} />
            <Route path="/shortlist" render={props => <ShortlistPage awards={this.state.awards} {...props} />} /> */}
            <Route path="/error" component={ErrorPage} />
            <Redirect from="/" to="/login" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
