import React from 'react';

const ErrorPage = props => {
        return (
            <React.Fragment>
                <h1>Sorry, but an error has occurred!</h1>  
                <div className="container">   
                    <p>Please go back and try again</p>
                </div>
            </React.Fragment>
        );
}
 
export default ErrorPage;