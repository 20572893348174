export const modalStyle = colour => {
    const style = {
        overlay: { 
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        content: {
            position: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
            margin: "0 auto",
            textAlign: "center",
            color: colour
        }
    }
    return style;
}